
body {
  padding-top:60px;
}

nav {
  background-color: #00f;
  overflow: hidden;
  padding: 1em;
  border-bottom: 1px solid #000;
}
nav a {
  color: #fff;
  text-transform:uppercase;
  font-size:1rem;
  font-weight:600;
}
nav a:visited {
  color: #fff;
}
nav .navWide {
  display: none;
  margin: 0 auto;
  height:40px;
  line-height:40px;
}
nav .navWide .wideDiv {
  text-align: center;
}
nav .navWide .wideDiv a {
  text-decoration: none;
  display: inline-block;
  padding: 0 2em;
}

nav .navNarrow i {
  float: left;
  cursor: pointer;
  color: #fff;
}
nav .navNarrow .narrowLinks {
  display: none;
  list-style:none;
  padding-left:0;
  margin-top:35px;
}
nav .navNarrow .narrowLinks a {
  text-decoration: none;
  display: block;
  float: left;
  clear: left;
  padding: 0.5em 0;
}

.navbar {
  min-height:60px;
  display:block!important;
  border-bottom:1px solid #FFF;
}

.menu-active {
  color:#FF0051!important;
}

.mobile-button-container {
  position:absolute;
  top:16px;
}

/* Font Awesome style */
.svg-inline--fa.fa-w-14 {
  color:#FFF;
  font-size:20px;
}

nav .navbar-brand {
  display:inline-block;
  float:right;
}

nav .nav-item {
  display:inline-block;
}

nav .nav-item-mobile {
  display:block;
}

.row {
  margin-left:0!important;
  margin-right:0!important;
}

/* Home Page styles */
.home-background{
  /* background:#373A3C; */
  background: url(/images/sunset_banner_large.jpg);
  background-repeat:no-repeat;
  background-position:center;
  height:600px; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.home_column {
  color:#FFF;
  margin-left:auto;
  margin-right:auto;
  padding-top:90px;
  text-align:center;
  /* padding:250px; */
}

.home_column h1 {
  font-size:4rem;
  font-weight:700;  
}

.home_column h2 {
  font-weight:700;
}

.home_column p{
  font-size:2rem;
  font-weight:700;
}

.key_point {
  font-weight:500;
}

/* About Section Styles */

.flex-row {
  flex-direction: row;
}

.About-Wrapper {
  
}

.About-container h2 {
  text-align:center;
}

.About-Points{
  border-radius:50%;  
  text-align:center;
  width:100px;
  height:100px; 
  background:#54B8FB;
  margin:10px auto;
}

.About-Info {
  text-align:center;
}

.About-Points > .svg-inline--fa.fa-w-10,
.About-Points > .svg-inline--fa.fa-w-16,
.About-Points > .svg-inline--fa.fa-w-14{
  color:#FFF;
  font-size:2.5rem;
  position:relative;
  top: calc(50% - 20px); /* 50% - 3/4 of icon height */  
}

.svg-inline--fa.fa-w-14:hover {
  cursor:pointer;
}

.div_spacer_about,
.div_spacer_normal {
  height:60px;
}

.About-Profile-Container {
  float:none;
  margin:20px auto;
  display:block!important;
  text-align:center;
}

.About-Profile-Container img {
  display:block;
  margin:10px auto;
  border-radius:50% 50%;
}

.progress {
  height:2rem!important;
}

.progress-container {
float:none;
margin:20px auto;  
}

.progress-container h2 {
  margin-bottom:20px;
}

.progress_skill {
  display:block;
  clear:both;
}

.progress_skill_label  {
  display:inline-block;
  padding-right:2rem;
  float:left;
}

.progress_skill_label h2 {
  font-size:24px;
}

.progress-striped-main-container  {
  display:inline-block;  
  float:left;
}

.progress_percentage {
  font-size:1.5rem;
}

.progress-bar-success-seventy {
  animation: progress-bar-fill-seventy ease-in 3s;
  animation-fill-mode: forwards;  
}

.progress-bar-success-sixty {
  animation: progress-bar-fill-sixty ease-in 3s;
  animation-fill-mode: forwards;
}

.progress-bar-success-fifty {
  animation: progress-bar-fill-fifty ease-in 3s;
  animation-fill-mode: forwards;
}

.progress-bar-success-fourty {
  animation: progress-bar-fill-fourty ease-in 3s;
  animation-fill-mode: forwards;
}

.progress-bar-success-thirty {
  animation: progress-bar-fill-thirty ease-in 3s;
  animation-fill-mode: forwards;
}

.progress-bar-success-twenty {
  animation: progress-bar-fill-twenty ease-in 3s;
  animation-fill-mode: forwards;
}

.progress-bar-success-ten {
  animation: progress-bar-fill-ten ease-in 3s;
  animation-fill-mode: forwards;
}

@keyframes progress-bar-fill-seventy {
  from {
    width: 0%;
  }
  to {
    width: 70%;
  }
}


@keyframes progress-bar-fill-sixty {
  from {
    width: 0%;
  }
  to {
    width: 60%;
  }
}

@keyframes progress-bar-fill-fifty {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}

@keyframes progress-bar-fill-fourty {
  from {
    width: 0%;
  }
  to {
    width: 40%;
  }
}

@keyframes progress-bar-fill-thirty {
  from {
    width: 0%;
  }
  to {
    width: 30%;
  }
}

@keyframes progress-bar-fill-twenty {
  from {
    width: 0%;
  }
  to {
    width: 20%;
  }
}

@keyframes progress-bar-fill-ten {
  from {
    width: 0%;
  }
  to {
    width: 10%;
  }
}

@media (min-width: 960px) {
  nav .navWide {
    display: inline-block;
    float:left;
  }
  nav .navNarrow {
    display: none;
  }
}

@media screen (min-width:768px) and (max-width:960px){
  .div_spacer_about,
  .div_spacer_normal {
    height:120px;
  }
}

@media screen and (max-width:768px) {
  .div_spacer_about {
    height:40px;
  }
}

@media screen and (max-width:576px) {
  .progress_skill_label h2 {
    margin-top:20px;
  }
}

/* Image Gallery Styles */

.Image-Gallery-Container {
  display:flex;  
  flex-flow:row wrap;
}

.Portfolio-Header h2 {
  text-align:center;
}

.Image-Gallery-Image-Portfolio {
  padding-bottom:15px;
  padding-top:15px;
}

.Image-Gallery-Image-Portfolio a,
.Image-Gallery-Image-Portfolio a:hover {
  text-decoration:none;
  text-align:center;
}

.Image-Gallery-Image-Portfolio img {
  max-width:100%;
  width:100%;
}

.portfolio_caption {
  position:relative;
  background-color:#464646;
  color:#FFF;
}

/* Contact Styles */
.contact-message {
  text-align:center;
}

/* Responsive Styles */ 

@media screen and (max-width:768px){
  .Image-Gallery-Container {display:block;}
  .Image-Gallery-Image-Portfolio {display:block;margin:10px auto;float:none;}
}

@media screen and (max-width:500px) {
  .home_column {padding-top:200px;}
  .home_column h1 {font-size:2.5rem;}
}


